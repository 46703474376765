<template>
  <div>
    <div class='text-center'>
      <div style='display: flex'>
        <div>
          <el-avatar :size='`large`' :src='user.avatar' @error='true'></el-avatar>
        </div>
        <div style='flex: auto;display: flex;flex-direction: column;text-align: left;padding-left: 10px'>
          <div>
            <svg-icon icon-class='peoples'></svg-icon>
            {{ user.nickname || '-' }}
          </div>
          <div>
            <svg-icon icon-class='phone' />
            {{ user.mobile || '-' }}
          </div>
          <div>
            <svg-icon icon-class='email' />
            {{ user.email || '-' }}
          </div>
        </div>
      </div>
      <br>
    </div>
    <ul class='list-group list-group-striped'>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />创建日期
        <div class='pull-right'>{{ $utils.parseTime(user.created_at) }}</div>
      </li>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />最近登录
        <div class='pull-right'>{{ $utils.parseTime(user.login_time) }}</div>
      </li>
    </ul>
    <ul class='list-group list-group-striped' v-if='user.extra'>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />钉钉昵称
        <div class='pull-right'><span :title='extraInfo.userid'>{{ extraInfo.nick || '-' }}</span></div>
      </li> <li class='list-group-item'>
        <i class='iconfont icon-date' />工号
        <div class='pull-right'>{{ extraInfo.jobnumber || '-' }}</div>
      </li>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />归属部门
        <div class='pull-right' v-if='extraInfo.ding_dpt_users'>
          <el-tag effect='plain' v-for='(dept) in extraInfo.ding_dpt_users' :key='dept.id'>
            {{ dept.ding_dpt ? dept.ding_dpt.name : '--' }}
          </el-tag>
        </div>
      </li>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />入职时间
        <div class='pull-right'>
          <el-tag effect='plain'>
            {{ dingUserInfo.hired_at ? dingUserInfo.hired_at : '未知' }}
          </el-tag>
        </div>
      </li>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />在职状态
        <div class='pull-right'>
          <el-tag type='success' effect='plain' v-if="dingUserInfo.is_leave=='N'">在职</el-tag>
          <el-tag type='danger' effect='plain' v-else-if="dingUserInfo.is_leave=='Y'">离职({{dingUserInfo.leave_date}})</el-tag>
          <el-tag effect='plain' v-else>未知</el-tag>
        </div>
      </li>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />工作邮箱
        <div class='pull-right'>
          <el-tag effect='plain'>
            {{ dingUserInfo ? dingUserInfo.email : '--' }}
          </el-tag>
        </div>
      </li>
      <li class='list-group-item'>
        <i class='iconfont icon-date' />办公地址
        <div class='pull-right'>
          <el-tag effect='plain'>
            {{ dingUserInfo.workPlace ? dingUserInfo.workPlace : '--' }}
          </el-tag>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UserBaseInfo',
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    extraInfo() {
      return this.user ? this.user.extra : null
    },
    dingUserInfo() {
      return this.extraInfo ? this.extraInfo.user_info : {}
    }
  }
}
</script>

<style scoped>
.list-group-striped > .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.list-group {
  padding-left: 0;
  list-style: none;
}

.list-group-item {
  border-bottom: 1px solid #e7eaec;
  border-top: 1px solid #e7eaec;
  margin-bottom: -1px;
  padding: 11px 0;
  font-size: 13px;
}

.text-center {
  text-align: center;
}

.box-card {
  margin-bottom: 20px;
}
</style>
