<template>
  <div>
<!--    <div slot='header' class='clearfix'>-->
<!--      <span class='title'>曾任职部门记录 &nbsp;<el-link title='最近50条任职部门记录' type='primary' icon='el-icon-refresh'-->
<!--                                                 @click='getList' /></span>-->
<!--    </div>-->
    <div>
      <el-timeline>
        <el-timeline-item :timestamp='item.created_at' placement='top' v-for='(item,index) in dataList' :key='index'>
          {{ item.ding_dpt ? item.ding_dpt.name : '' }}
        </el-timeline-item>
      </el-timeline>
    </div>

  </div>
</template>

<script>
export default {
  name: 'UserDeptLog',
  props: {
    userId: {
      type: [Number, String],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      dataList: []
    }
  },
  methods: {
    async getList() {
      if (this.userId) {
        let { list } = await this.$api.getUserDeptList(this.userId)
        this.dataList = [...list]
      }

    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

.title {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>