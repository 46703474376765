<template>
  <div style='width: 100%'>
    <div class='table-search'>
      <el-form :model='searchCondition' inline size='mini'>
        <el-form-item label='API路由'>
          <el-input v-model='searchCondition.router' placeholder='输入路由' clearable></el-input>
        </el-form-item>
        <!--            <el-form-item label='处理状态'>-->
        <!--              <el-select placeholder='处理状态' v-model='searchCondition.deal_status' clearable>-->
        <!--                <el-option :label='label' :value='value' v-for='(label,value) in dealStatusMap' :key='value'>-->
        <!--                  <span style='float: left'>{{ label }} - {{ value }}</span>-->
        <!--                </el-option>-->
        <!--              </el-select>-->
        <!--            </el-form-item>-->
        <el-form-item label='创建时间'>
          <el-date-picker
            v-model='searchCondition.date_range'
            type='daterange'
            align='right'
            unlink-panels
            range-separator='至'
            start-placeholder='开始日期'
            end-placeholder='结束日期'
            value-format='yyyy-MM-dd'
            :picker-options='pickerOptions'>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='getList'>搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--    渲染表格-->
    <m-table :showIndex='true' :offset='offset' max-height='500' :tableData='dataList' :columns='columns'
             :loading='loadingStatus'>
      <el-table-column label='操作' width='100' align='center'>
        <template slot-scope='{row}'>
          <el-button size='mini' type='text' icon='el-icon-view' @click='handleView(row)'>详情</el-button>
        </template>
      </el-table-column>
    </m-table>
    <el-row>
      <el-col :span='2'>
        <div style='line-height: 50px'>
          <el-button :loading='loadingStatus' :disabled='loadingStatus' type='text' icon='el-icon-refresh'
                     @click='getList'>刷新
          </el-button>
        </div>
      </el-col>
      <el-col :span='22'>
        <!--    渲染分页-->
        <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                      @pagination='getList' />
      </el-col>
    </el-row>
    <!-- 操作日志详细 -->
    <el-dialog title='操作日志详细' :visible.sync='open' width='70%' append-to-body>
      <el-form ref='form' :model='form' label-width='100px' size='mini'>
        <el-row>
          <el-col :span='12'>

            <el-form-item label='操作模块：'>{{ form.service_name }}</el-form-item>

            <el-form-item
              label='登录信息：'
            >{{ form.username }} / {{ form.ip }}
            </el-form-item>

          </el-col>

          <el-col :span='24'>
            <el-form-item label='请求方式：'>{{ form.method }}</el-form-item>
          </el-col>

          <!--            <el-col :span='24'>-->
          <!--              <el-form-item label='操作方法：'>{{ form.method }}</el-form-item>-->
          <!--            </el-col>-->

          <el-col :span='24'>
            <el-form-item label='请求参数：'>
              <el-input :autosize='{ minRows: 15, maxRows: 30}' type='textarea'
                        :value='jsonRequestData'></el-input>
            </el-form-item>
          </el-col>

          <el-col :span='24'>
            <el-form-item label='返回参数：'>
              <el-input :autosize='{ minRows: 15, maxRows: 30}' type='textarea'
                        :value='jsonResult'></el-input>
            </el-form-item>

          </el-col>

          <!--            <el-col :span='12'>-->
          <!--              <el-form-item label='操作状态：'>-->
          <!--                <div v-if='form.status === 1'>正常</div>-->
          <!--                <div v-else>失败</div>-->
          <!--              </el-form-item>-->
          <!--            </el-col>-->

          <el-col :span='24'>
            <el-form-item label='操作时间：'>{{ form.created_at }}</el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='open = false'>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UserOptLog',
  props: {
    userId: {
      type: [Number, String],
      default() {
        return null
      }
    }
  },
  computed: {
    jsonResult() {
      if (this.form && this.form.response_data && this.form.response_data.indexOf('{') > -1)
        return JSON.stringify(JSON.parse(this.form.response_data), null, 4)
      else
        return this.form.response_data
      // return this.formData.notify_msg || {}
    },
    jsonRequestData() {
      if (this.form && this.form.request_data && this.form.request_data.indexOf('{') > -1)
        return JSON.stringify(JSON.parse(this.form.request_data), null, 4)
      else
        return this.form.request_data
      // return this.formData.notify_msg || {}
    }
  },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 搜索条件
      searchCondition: { user_id: null },
      loadingStatus: true,
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        // {
        //   title: '日志编号',
        //   value: 'id',
        //   width: 80
        // },
        {
          title: '操作类型',
          value: 'method',
          width: 100
        },
        {
          title: 'API路由',
          value: 'router',
          width: 100
        },
        {
          title: '服务名称',
          value: 'service_name',
          width: 100
        },
        {
          title: '操作人员',
          value: 'username',
          width: 100
        },
        {
          title: '主机IP',
          value: 'ip',
          width: 120
        },
        {
          title: '备注',
          value: 'remark',
          width: 200
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 140
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 140
        }
      ],
      // 表格列表数据
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        current_page: 1,
        page_size: 10
      }
    }
  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.userId) {
        condition.created_by = this.userId
      }
      if (this.searchCondition.router) {
        condition.router = this.searchCondition.router.trim()
      }

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.dataList = []
      if (this.userId) {
        this.loadingStatus = true
        let searchCondition = this.handleSearchCondition()
        // 合并
        Object.assign(searchCondition, this.pagingData)
        let { list, pages } = await this.$api.getSystemOperLogList(searchCondition)
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      }
    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    }

  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>
