<template>
  <div style='width: 100%'>
    <!--    渲染表格-->
    <m-table :showIndex='true' :offset='offset' max-height='500' :tableData='dataList' :columns='columns'
             :loading='loadingStatus' />
    <!--    渲染分页-->
    <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                  @pagination='getList' />
  </div>
</template>

<script>
export default {
  name: 'EditLog',
  props: {
    userId: {
      type: [Number, String],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      // 搜索条件
      searchCondition: { user_id: null },
      loadingStatus: true,
      columns: [
        // {
        //   title: '记录ID',
        //   value: 'id',
        //   width: 80
        // },
        // {
        //   title: '类型',
        //   value: '_op_type',
        //   width: 100
        // },
        {
          title: '修改内容',
          value: 'mgs',
          width: 400,
        },
        {
          title: '记录时间',
          value: 'created_at',
          width: 140
        },
        {
          title: '更新时间',
          value: 'created_at',
          width: 140
        }
      ],
      // 表格列表数据
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        current_page: 1,
        page_size: 10
      }
    }
  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.userId) {
        condition.info_id = this.userId
      }
      return condition
    },
    async getList() {
      this.dataList = []
      if (this.userId) {
        this.loadingStatus = true
        let searchCondition = this.handleSearchCondition()
        // 合并
        Object.assign(searchCondition, this.pagingData)
        let { list, pages } = await this.$api.getUserEditLog(searchCondition)
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      }
    }

  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>
