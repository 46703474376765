<template>
  <page-header-layout v-show='this.$route.matched.length===2'>
    <div class='main-page-content'>
      <div v-if='userPermissions.indexOf("user_detail")>-1'>
        <div class='panel' style='display: flex;'>
          <el-card class='box-card' style='width: 400px'>
            <div slot='header' class='clearfix'>
            <span class='title'>基本信息 &nbsp;<el-link :title='userId' type='primary' @click='getUserInfo'
                                                        icon='el-icon-refresh' /></span>
            </div>
            <div>
              <user-base-info :user='user' />
            </div>
            <div style='margin-top: 20px'>
              <UserDeptLog :user-id='userId'></UserDeptLog>
            </div>
          </el-card>
          <el-card class='box-card' style='width: 400px;margin-left: 10px'>
            <div slot='header' class='clearfix'>
              <span class='title'>任职部门记录</span>
            </div>
            <div>
              <UserDeptLog :user-id='userId' v-if='userId'></UserDeptLog>
            </div>
          </el-card>
        </div>
        <br/>
        <div class='panel'>
          <el-tabs v-model='activeName' type='card'>
            <el-tab-pane label='登录日志' name='first'>
              <el-card class='box-card' v-if='activeName==="first"'>
                <!--                <div slot='header' class='clearfix'>-->
                <!--                  <div class='title'>登录日志-->
                <!--                    &nbsp;<el-link :title='userId' type='primary' @click='loadUserLoginLog'-->
                <!--                                   icon='el-icon-refresh' />-->
                <!--                  </div>-->
                <!--                </div>-->
                <div>
                  <UserLoginLog ref='refUserLoginLog' :user-id='userId' />
                </div>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label='账号角色权限变更日志' name='second'>
              <el-card v-if='activeName==="second"'>
                <EditLog :user-id='userId' v-if='userId' />
              </el-card>
            </el-tab-pane>
            <el-tab-pane label='操作日志' name='third'>
              <el-card v-if='activeName==="third"'>
                <UserOptLog :user-id='userId' v-if='userId' />
              </el-card>
            </el-tab-pane>
          </el-tabs>
        </div>

      </div>
    </div>

  </page-header-layout>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import UserBaseInfo from '@/pages/user/components/UserBaseInfo'
import UserLoginLog from '@/pages/user/components/UserLoginLog'
import UserDeptLog from '@/pages/user/components/UserDeptLog'
import { mapGetters } from 'vuex'
import EditLog from '@/pages/user/EditLog'
import UserOptLog from '@/pages/user/UserOptLog'

export default {
  name: 'UserDetail',
  components: { UserOptLog, EditLog, UserDeptLog, UserLoginLog, UserBaseInfo, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      userId: null,
      user: {},
      activeName: 'first'
    }
  },
  methods: {
    async getUserInfo() {
      if (this.userId) {
        let { info } = await this.$api.getUserInfo(this.userId)
        let { extra } = await this.$api.getUserExtraInfo(this.userId)
        this.user = Object.assign({}, info, { extra: extra })
      } else {
        this.$notify.warning('用户ID未获取')
      }

    },
    loadUserLoginLog() {
      this.$refs['refUserLoginLog'].getList()
    }

  },
  mounted() {

  },
  created() {
    this.userId = this.$route.params ? this.$route.params.user_id : null
    if (this.userId)
      this.getUserInfo()
  }
}
</script>

<style scoped>
.panel {
    margin-left: 10px;
}

.title {
    font-size: 1.1rem;
    font-weight: bold;
}
</style>
